* {
    font-family: $main_font;
    margin: 0px;
    text-decoration: none;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 5px;
      }
}

:hover {
    /* Animations */
    transition: all 0.2s ease-in;
}

