
.auth__main {
    background: url('../../images/tasks.jpg') center no-repeat;
}

.auth__box-container {
    height: 100%;
    max-width: 550px;
    width: 550px;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 10px 0px 6px #00000026;
    display: flex;
    justify-content: center;

    @include tablet {
        width: 100% !important;
        max-width: 100% !important;
    }
}

.auth__content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    padding: 50px;
}

.auth__tittle-container {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    z-index: 1;
}

.auth__tittle {
    color: #2C3357;
    font-weight: 600;
    font-size: 30px;    

    @include littlePhone {
        font-size: 24px;
    }
}
