
.menu__item-container {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 100%;
    padding: 15px;
    padding-left: 35px;
    border: none;
    background-color: transparent;
    border-top: transparent 1px solid;
    border-bottom: transparent 1px solid;

    &:hover {
        cursor: pointer;
        border-top: $info 1px solid;
        border-bottom: $info 1px solid;
        box-shadow: 0px 0px 6px #00000026;
    }
}

.menu__item-name {
    color: $dark;
    font-size: 14px;
    font-weight: normal;
    margin-left: 15px;
}

.menu__item-name-selected {
    color: $primary;
    font-weight: 600;
}
