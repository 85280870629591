.input__primary-container {
    width: 100%;
    border-radius: 8px;
    opacity: 1;
    margin-top: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.input__primary-label {
    position: absolute;
    top: -25px;
    left: 5px;
    font-weight: normal;
    font-size: 13px;
    color: #2C3357;
}

.input__primary-input {
    height: 46px;
    width: 100%;
    padding-left: 20px;
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #B7BABC;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    &:focus{
        outline: none;
        border: 1px solid $primary;
    }
}

.input__primary-description {
    font-weight: normal;
    font-size: 12px;
    color: $primary-dark;
    margin-top: 15px;
}
