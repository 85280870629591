
.btn {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;
    transition: all .3s cubic-bezier(.19,1,.22,1);
    color: white;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    z-index: 1;

    @include phone {
        font-size: 14px;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: $grey-normal;
        border: 1px solid $border;
        color: $primary-dark;
        font-weight: normal;
    }


}

.btn-primary {
    background-color: $primary;

    &:hover:enabled {
        background-color: darken($color: $primary, $amount: 15);
        box-shadow: 0px 0px 10px #00000026;
        cursor: pointer;
    }
}

.btn-secondary {
    background-color: $grey-light;
    border: 1px solid $border;
    color: $primary-dark;
    font-weight: normal;

    &:hover:enabled {
        background-color: darken($color: $grey-light, $amount: 15);
        box-shadow: 0px 0px 10px #00000026;
        cursor: pointer;
    }

}

.btn-block {
    width: 100%;
}

.btn-link {
    color: $primary-light;
    font-weight: bold;
    z-index: 1;
    font-size: 16px;

    @include phone {
        font-size: 14px;
    }

    &:hover {
        color: darken($color: $primary-light, $amount: 15);
        text-decoration: underline;
    }
}
