// LoadingScreen
.sk-chase-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
  }

.sk-chase {
    width: 40px;
    height: 40px;
    position: fixed;
    animation: sk-chase 2.5s infinite linear both;
    z-index: 10000;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

  // LoadingComponent
.sk-chase-container-component {
  width: 100%;
  height: 80px;
  margin-top: 5px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sk-chase-component {
  width: 20px;
  height: 20px;
  position: fixed;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 10;
}

.sk-chase-dot-component {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot-component:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #006BC8;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot-component:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot-component:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot-component:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot-component:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot-component:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot-component:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot-component:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot-component:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot-component:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot-component:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot-component:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot-component:nth-child(6):before { animation-delay: -0.6s; }
