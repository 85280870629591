
.router-content {
    width: 100%;
    min-height: 100vh;
    display: inline-block; /* Para adaptar al contenido */
    height: 100vh;
}

// Logo

.general__logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    z-index: 1;
    margin-top: 20px;
}

.general__logo {
    width: 50px;
    @include littlePhone {
        width: 40px;
    }
}

.general__logoName {
    color: $primary-dark;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 3.4px;
    line-height: normal;
    font-family: $main_font;
    margin-left: 10px;

    @include littlePhone {
        font-size: 18px;
    }
}

.general__backgroundImageIcon1 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 250px;
    height: 250px;
}

.general__backgroundImageIcon2 {
    position: absolute;
    top: 0px;
    right: 5px;
    width: 120px;
    height: 120px;
}

.general__labelButton {
    color: $primary-dark;
    font-size: 16px;
    font-weight: normal;
    margin-top: 30px;
    margin-bottom: 5px;
    z-index: 1;

    @include phone {
        font-size: 14px;
    }
}

.general__description {
    color: $primary-dark;
    font-size: 16px;
    font-weight: normal;
    margin-top: 30px;
    z-index: 1;
}

.general__paragraph-container {
    width: 100%;
    z-index: 1;
}

.general__paragraph {
    color: #2C3357;
    font-size: 14px;
    margin-top: 20px;
}


