
// Products Screen

.products__main-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
}

.products__content {
    display: grid;
    margin-top: 20px;
    padding: 30px 100px;
    text-align: center;
    justify-items: center;
    grid-template-columns: repeat(6, 270px);
    row-gap: 40px;
    

    @media( max-width: 1500px ) {
        grid-template-columns: repeat(5, 270px);
    }

    @media( max-width: 1350px ) {
        grid-template-columns: repeat(4, 270px);
    }

    @media( max-width: 1150px ) {
        grid-template-columns: repeat(3, 270px);
    }

    @media( max-width: 900px ) {
        grid-template-columns: repeat(2, 270px);
    }

    @media( max-width: 600px ) {
        grid-template-columns: repeat(1, 270px);
    }


}

.products__image {

    width: auto;
    max-width: none;
    height: 100%;
    max-height: 300px;
}

.products__text-info-tittle {
    color: black;
    text-align: center;
    font-size: 14px;
    margin: 5px 0px;
    font-weight: normal;
}

.products__text-info-price {
    color: black;
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    font-weight: normal;
}

.products__text-info-fee {
    color: black;
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    font-weight: normal;
}

// New Product Screen

.products__main {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    margin: 0px;
    height: 100%;
    width: 100%;
}

.products__box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0px 3px $dark;
    border-radius: 30px;
    padding: 20px;
    width: 50%;
}

.products__tittle {
    color: darken($color: $primary, $amount: 30);
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 50px;
    text-align: center;

}

.products__input {
    color: $dark;
    border: 0px;
    border-bottom: 1px solid $grey-dark;
    font-size: 16px;
    margin-bottom: 15px;
    height: 20px;
    width: 100%;

    transition: border-bottom .3s ease;

    &:focus {
        border-bottom: 1px solid $primary;
        outline: none;
    }

}

.products__input-textarea {
    color: $dark;
    border: 1px solid $grey-dark;
    font-size: 16px;
    margin-bottom: 15px;
    height: 20px;
    width: 100%;

    transition: border-bottom .3s ease;

    &:focus {
        border-bottom: 1px solid $primary;
        outline: none;
    }

}

.products__input-select {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
}

.products__progressbar-container {
    width: 100%;
    border: 1px solid $grey-dark;
}

.products__progressbar {
    background-color: $primary;
}

.products__buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    width: 450px;
}
