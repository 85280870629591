
// Fonts

$main_font: 'Montserrat', sans-serif;
$second_font: 'Lato', sans-serif;
$third_font: 'Poppins', sans-serif;

// Tamaño de Media Queries

$littlePhone: 400px;
$phone: 750px;
$tablet: 1200px;
$notebook: 1350px;
$desktop: 1024px;

// Colors

$primary: #006BC8;
$primary-light: #0090FF;
$primary-dark: #2C3357;
$dark: #000E19;
$grey: #918A8D;
$grey-dark: #535353;
$grey-normal: #E7E6E7;
$grey-light: #FAFAFA;
$border: #B7BABC;
$danger: #C80000;
$danger-light: #FEDEDE;
$warning: #F5912D;
$warning-light: #FEEDDE;
$info: #7ABCF7;
$info-light: #DEEFFE;
$ok: #037F4C;
$ok-light: rgba(3, 127, 76, 0.08);

$space: 15px;
