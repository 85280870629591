/** Media Queries **/

@mixin littlePhone {
    @media (max-width: #{$littlePhone}) {
        @content;
    }
}

@mixin phone {
    @media (max-width: #{$phone}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$tablet}) {
        @content;
    }
}

@mixin notebook {
    @media (max-width: #{$notebook}) {
        @content;
    }
}
  
@mixin desktop {
    @media (max-width: #{$desktop}) {
        @content;
    }
}

@mixin response($maxWidth) {
    @media (max-width: #{$maxWidth}) {
        @content;
    }
}

@mixin grid($columns, $space ) {
    display: grid;
    gap: $space;
    grid-template-columns: repeat($columns, 1fr);
}